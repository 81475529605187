import React, { useEffect, useRef } from "react";
import { Root } from "./style";

import { useState } from "@hookstate/core";
import { formatNumber, useSiteData } from "utils";
import { useWeb3 } from "web3";

import RawHTML from "components/Common/RawHTML";

const Calculator = () => {
  const { index: page } = useSiteData().pages;
  const { bsc } = useWeb3();

  const [title, ...lines] = page.calculator.split("\n");

  const state = useState({
    bnbInvested: null,
    usdInvested: null,
    tokensInvested: null,
    weeklyBnbReward: 0,
    weeklyUsdReward: 0,
    yearlyBnbReward: 0,
    yearlyUsdReward: 0,
  });

  state.attach(() => ({
    init: () => ({
      onSet: ({ path, value }) => {
        if (path[0] === "tokensInvested") {
          let bnbPool = bsc.rewardPoolBNB;
          if (bnbPool > bsc.rewardPoolHardcap) {
            bnbPool = bsc.rewardPoolHardcap;
          }

          const rewardPoolShare = bsc.rewardPoolShare;
          const reward = (bnbPool * value) / rewardPoolShare;

          state.weeklyBnbReward.set(reward);
          state.weeklyUsdReward.set(reward * bsc.bnbUsdPrice);
          state.yearlyBnbReward.set(reward * 52);
          state.yearlyUsdReward.set(reward * 52 * bsc.bnbUsdPrice);
        }
      },
    }),
  }));

  const bnbInvested = useRef();
  const usdInvested = useRef();
  const tokensInvested = useRef();

  const setBnb = (event) => {
    const amount = Number(event.target.value);
    state.bnbInvested.set(amount);
    state.usdInvested.set(state.bnbInvested.get() * bsc.bnbUsdPrice);
    state.tokensInvested.set(
      (state.usdInvested.get() / bsc.hodlUsdPrice) * 0.9
    );

    usdInvested.current.value = formatNumber(state.usdInvested.get());
    tokensInvested.current.value = formatNumber(state.tokensInvested.get());
  };

  const setUsd = (event) => {
    const amount = Number(event.target.value);
    state.usdInvested.set(amount);
    state.bnbInvested.set(state.usdInvested.get() / bsc.bnbUsdPrice);
    state.tokensInvested.set(
      (state.usdInvested.get() / bsc.hodlUsdPrice) * 0.9
    );

    bnbInvested.current.value = formatNumber(state.bnbInvested.get());
    tokensInvested.current.value = formatNumber(state.tokensInvested.get());
  };

  const setTokens = (event) => {
    const amount = Number(event.target.value.replace(/,/g, ""));
    state.tokensInvested.set(amount);
    state.usdInvested.set(
      state.tokensInvested.get() * bsc.hodlUsdPrice * 1.1111
    );
    state.bnbInvested.set(state.usdInvested.get() / bsc.bnbUsdPrice);

    usdInvested.current.value = formatNumber(state.usdInvested.get());
    bnbInvested.current.value = formatNumber(state.bnbInvested.get());
  };

  useEffect(() => {
    setTimeout(() => setBnb({ target: { value: 1 } }), 1000);
  }, []);

  return (
    <Root className="body-width">
      <a id="calculator"></a>
      <div className="calculator">
        <div className="callToAction">
          <h3>{title}</h3>
          <RawHTML
            html={lines
              .join("<br/>")
              .replace(/\$[A-Za-z]*/g, (m) => `<strong>${m}</strong>`)}
          />
        </div>
        <ul className="input">
          <li>
            <label>Invest</label>
            <input
              type="text"
              onChange={setBnb}
              ref={bnbInvested}
              autoComplete="0"
              defaultValue="1"
            />
            <strong className="bnb">BNB</strong>
          </li>
          <li>
            <label>Invest</label>
            <input
              type="text"
              onChange={setUsd}
              ref={usdInvested}
              autoComplete="0"
            />
            <strong className="usd">USD</strong>
          </li>
          <li>
            <label>Tokens</label>
            <input
              type="text"
              onChange={setTokens}
              ref={tokensInvested}
              autoComplete="0"
            />
            <strong className="hodl">HODL</strong>
          </li>
        </ul>
        <ul className="output">
          <li>
            <label>Weekly reward</label>
            <span>
              {formatNumber(state.weeklyBnbReward.get(), 4)} BNB&nbsp;/&nbsp; $
              {formatNumber(state.weeklyUsdReward.get())}
            </span>
          </li>
          <li>
            <label>Annual rewards</label>
            <span>
              {formatNumber(state.yearlyBnbReward.get(), 4)} BNB &nbsp;/&nbsp; $
              {formatNumber(state.yearlyUsdReward.get())}
            </span>
          </li>
          {false ? (
            <li>
              <label>Recover investment in</label>
              <span className="days">
                {(
                  state.bnbInvested.get() / state.weeklyBnbReward.get()
                ).toFixed(0)}
                &nbsp; weeks
              </span>
            </li>
          ) : (
            ""
          )}
        </ul>
      </div>
    </Root>
  );
};

export default Calculator;
