import React, { useEffect, useState } from 'react';
import { Root } from './style';

import { formatNumber } from 'utils';
import { useWeb3 } from 'web3';

import vars from 'variables';

const RawHTML = ({ html, className, style }) => {
  const { bsc } = useWeb3();
  const [raw, setRaw] = useState('');

  useEffect(() => {
    const scriptTags = [];

    (html || '')
      .replaceAll('\n', ' ')
      .replace(/<script .*?<\/script>/g, (script) => {
        const element = document.createElement(null);
        element.innerHTML = script;
        const scriptTag = element.firstChild;

        if (scriptTag.id && document.getElementById(scriptTag.id)) {
          console.log(scriptTag.id, 'already exists');
          return;
        }

        const fn = () => {
          const tag = document.createElement('script');
          tag.id = scriptTag.id;
          tag.src = scriptTag.src;
          document.body.appendChild(tag);
          tag.insertAdjacentHTML('afterend', '');
          scriptTags.push(tag);
        };

        if (
          document.readyState === 'complete' ||
          document.readyState === 'interactive'
        ) {
          fn();
        } else {
          document.addEventListener('DOMContentLoaded', fn);
        }
      });

    return () => {
      scriptTags.forEach((scriptTag) => {
        document.body.removeChild(scriptTag);
      });
    };
  }, []);

  useEffect(() => {
    setRaw(
      (html || '')
        .replace(
          /\bhref=("|')(page|link):(\w+)\1/g,
          (match, quote, linkType, key) =>
            `href=${quote}${vars[linkType + 's'][key]}${quote}${
              linkType === 'link' ? ' target="_blank" rel="noreferrer"' : ''
            }`
        )
        .replace(
          /\bhref=("|')modal:(\w+)\1/g,
          (match, quote, key) =>
            `href=${quote}#${quote} data-modal=${quote}${key}${quote}`
        )
        .replace(/\{BURNED\}/g, formatNumber(Math.round(bsc.burnWalletHODL)))
        .replace(
          /\{CIRCULATING\}/g,
          formatNumber(Math.round(bsc.circulatingSupply))
        )
        .replace(
          /\{REWARDPOOLSHARE\}/g,
          formatNumber(Math.round(bsc.rewardPoolShare))
        )
        .replace(
          /\{TOTAL\}/g,
          formatNumber(Math.round(10000000000 - bsc.burnWalletHODL))
        )
        .replace(
          /\{TREASURY\}/g,
          formatNumber(Math.round(bsc.projectFunds?.treasury ?? 0))
        )
        .replace(
          /\{REWARDPOOL\}/g,
          formatNumber(Math.round(bsc.projectFunds?.rewardpool ?? 0))
        )
        .replace(
          /\{LIQUIDITY\}/g,
          formatNumber(Math.round(bsc.projectFunds?.liquidity ?? 0))
        )
        .replace(
          /\{MMFUNDS\}/g,
          formatNumber(Math.round(bsc.projectFunds?.mmfunds ?? 0))
        )
        .replace(/\{X\}/g, formatNumber(Math.round(bsc.contractHODL ?? 0)))
        .replace(
          /\{LP\}/g,
          formatNumber(Math.round(bsc.liquidityPoolHODL ?? 0))
        )
        .replace(/<script .*?<\/script>/g, '')
    );
  }, [
    bsc.burnWalletHODL,
    bsc.circulatingSupply,
    bsc.liquidityPoolHODL,
    bsc.projectFunds,
  ]);

  return (
    <Root>
      <div
        dangerouslySetInnerHTML={{ __html: raw }}
        className={`raw-html ${className || ''}`}
        style={style}
      />
    </Root>
  );
};

export default RawHTML;
