import styled from 'styled-components';

import vars from 'variables';

import coins from 'assets/Claim/coin.svg';
import coins1 from 'assets/Claim/coins1.svg';
import coins2 from 'assets/Claim/coins2.svg';
import coins3 from 'assets/Claim/coins3.svg';
import background from 'assets/Claim/Page/Connected/background.png';
import info from 'assets/Claim/Page/Connected/info.svg';
import time from 'assets/Claim/Page/Connected/time.png';

const Root = styled.div`
  .loading & .header *,
  .loading & .body-width {
    opacity: 0;
  }

  .header {
    position: relative;
    background-color: rgb(0, 3, 22);
    background-repeat: no-repeat;
  }

  .header .dollarAmount {
    color: ${vars.colors.white};
    display: block;
    letter-spacing: 1px;
  }

  .header label {
    color: ${vars.colors.grey};
  }

  h4 span {
    font-weight: 500;
    letter-spacing: 1px;
  }

  strong {
    margin-top: 5px;
    font-size: 1.4rem;
    display: block;
  }

  .status .buttons {
    margin: 0 auto;
    margin-top: -30px;
    margin-bottom: 30px;
  }

  .status .buttons strong {
    margin-left: 20px;
    margin-right: 20px;
    display: inline;
  }

  .info span {
    margin-left: -5px;
    padding-left: 35px;
    display: inline-block;
    background: url(${info}) no-repeat center left;
  }

  .info span a {
    cursor: pointer;
    border-bottom: 1px solid white;
  }

  .hr {
    margin: 0 auto;
    background: ${vars.colors.blue};
    border-radius: 3px;
  }

  .your-account p {
    color: ${vars.colors.grey};
  }

  .your-account ul {
    margin-top: 10px;
    text-transform: uppercase;
  }

  .your-account li {
    background: ${vars.colors.background};
  }

  .your-account li:empty {
    background: none;
  }

  .your-account li.blue {
    background: url(${background}) no-repeat;
    background-size: cover;
  }

  .your-account label,
  .your-account span,
  .your-account small {
    opacity: 0.4;
  }

  .your-account span {
    font-weight: 600;
  }

  .your-account strong {
    margin-top: -5px;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
  }

  .your-account small {
    margin-top: -9px;
  }

  ${vars.desktop.mediaQuery} {
    .header {
      margin-top: -120px;
      padding-top: 175px;
      background-image: url(${coins1}), url(${coins2}), url(${coins3}),
        url(${coins});
      background-position: top 300px left, top 200px right, top 850px right,
        top 950px left 275px;
      background-size: auto, auto, auto, auto;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
    }

    .header .dollarAmount {
      padding-top: 10px;
      padding-bottom: 30px;
      font-size: 1.5rem;
    }

    .status .buttons strong {
      font-size: 1.1rem;
    }

    .status p {
      margin-bottom: 10px;
      font-size: 1.35rem;
      letter-spacing: 0.5px;
    }

    .hr {
      width: 30px;
      height: 5px;
      margin-top: 80px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 1.75rem;
      font-weight: 600;
    }

    p {
      width: 600px;
      margin: 0 auto;
      padding: 15px 0 25px;
    }

    p.forecast div {
      margin-top: 10px;
    }

    .status a.button {
      padding-left: 30px;
      padding-right: 30px;
    }

    .info {
      margin-top: 50px;
      margin-bottom: 140px;
      padding-bottom: 100px;
      font-size: 1rem;
      line-height: 200%;
    }

    .your-account ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .your-account li {
      height: 155px;
      margin-bottom: 20px;
      display: flex;
      flex: 0 0 calc(33.3% - 12px);
      flex-direction: column;
      justify-content: center;
      border-radius: 10px;
    }

    .your-account img {
      height: 65px;
      margin-bottom: -5px;
    }

    .your-account div div {
      margin-bottom: -11px;
    }

    a.button.blue.logo {
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .header {
      margin-top: -85px;
      padding-top: 130px;
      background-image: url(${coins1}), url(${coins2}), url(${coins3}),
        url(${coins});
      background-position: top 90px left, top 95px right, top 550px right,
        top 465px left -40px;
      background-size: 125px, 70px, 50px, 70px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    .header .dollarAmount {
      margin-top: -3px;
      margin-bottom: 13px;
      font-size: 0.8rem;
    }

    .status .buttons {
      margin-top: -23px;
    }

    .status .buttons strong {
      font-size: 0.8rem;
    }

    .status p {
      margin-bottom: 30px;
      color: ${vars.colors.white};
      font-size: 0.85rem;
      font-weight: 300;
    }

    p.forecast {
      margin-top: -15px;
      margin-bottom: 20px;
      font-size: 0.65rem;
      letter-spacing: 0.25px;
    }

    p.forecast div {
      margin-top: 5px;
    }

    h1 {
      font-size: 2.4rem;
    }

    .status a.button {
      padding-left: 20px;
      padding-right: 20px;
    }

    .info {
      margin-bottom: 25px;
      padding-top: 15px;
      padding-bottom: 25px;
      font-size: 0.65rem;
      display: inline-block;
      background-size: 13px;
    }

    .info span {
      padding-left: 20px;
      background-size: 0.85rem;
    }

    .hr {
      width: 9px;
      height: 2px;
      margin-top: 50px;
      margin-bottom: 4px;
    }

    .your-account h3 {
      font-size: 0.85rem;
    }

    .your-account li {
      height: 80px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
    }

    .your-account li.rank {
      height: auto;
      padding-bottom: 12px;
    }

    .your-account label {
      margin-bottom: 4px;
      display: block;
      font-size: 0.75rem;
    }

    .your-account strong {
      font-size: 0.85rem;
    }

    .your-account img {
      height: 55px;
      margin-top: 16px;
      margin-bottom: 4px;
    }

    .your-account div div {
      margin-bottom: -2px;
      font-size: 0.75rem;
    }

    .your-account small {
      font-size: 0.65rem;
    }

    a.button.blue.logo {
      margin-top: 12px;
      margin-bottom: 45px;
    }
  }
`;

const Message = styled.div`
  text-align: center;

  h4 span {
    font-weight: 600;
    letter-spacing: 2px;
  }

  h2 {
    margin-top: 5px;
    line-height: 120%;
  }

  label {
    color: ${vars.colors.grey};
    display: block;
  }

  p {
    letter-spacing: 0.5px;
  }

  p.light {
    font-weight: 300;
  }

  a.button {
    margin-top: 25px;
    margin-bottom: -10px;
  }

  a.button.proceed {
    margin-bottom: 10px;
  }

  ${vars.desktop.mediaQuery} {
    & {
      width: 650px;
    }

    label {
      margin-top: 15px;
      margin-bottom: 8px;
    }

    p {
      font-size: 1.35rem;
    }

    p.light {
      margin-top: 15px;
      font-size: 1.1rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      width: calc(100vw - 80px);
      padding: 0 0 30px 8px;
    }

    h2 {
      font-size: 1.85rem;
    }

    label {
      margin-top: 8px;
      margin-bottom: -3px;
    }

    p {
      font-size: 0.85rem;
    }

    p.light {
      margin-top: 25px;
      color: white;
      font-size: 0.9rem;
    }
  }
`;

export { Root, Message };
