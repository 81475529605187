import React from 'react';
import { Root } from './style';

import PageHeader from 'components/Common/PageHeader';
import HelpUs from 'components/Common/HelpUs';
import SiteSection from 'components/Common/SiteSection';

import blueWhale from 'assets/HODLRanks/Rank/blue-whale.svg';
import crab from 'assets/HODLRanks/Rank/crab.svg';
import dolphin from 'assets/HODLRanks/Rank/dolphin.svg';
import humpbackWhale from 'assets/HODLRanks/Rank/humpback-whale.svg';
import killerWhale from 'assets/HODLRanks/Rank/killer-whale.svg';
import kraken from 'assets/HODLRanks/Rank/kraken.svg';
import megalodon from 'assets/HODLRanks/Rank/megalodon.svg';
import octopus from 'assets/HODLRanks/Rank/octopus.svg';
import shark from 'assets/HODLRanks/Rank/shark.svg';
import shrimp from 'assets/HODLRanks/Rank/shrimp.svg';
import swordfish from 'assets/HODLRanks/Rank/swordfish.svg';
import tunaFish from 'assets/HODLRanks/Rank/tuna-fish.svg';

import Rank from './Rank';

const HODLRanks = () => {
  return (
    <Root className="body-width">
      <PageHeader page="hodlRanks" />
      <div className="ranks">
        <Rank name="Megalodon" image={megalodon} threshold=">300M" />
        <Rank name="Kraken" image={kraken} threshold=">200M" />
        <Rank name="Blue Whale" image={blueWhale} threshold=">100M" />
        <Rank name="Humpback Whale" image={humpbackWhale} threshold=">50M" />
        <Rank name="Killer Whale" image={killerWhale} threshold=">10M" />
        <Rank name="Shark" image={shark} threshold=">5M" />
        <Rank name="Dolphin" image={dolphin} threshold=">2.5M" />
        <Rank name="Swordfish" image={swordfish} threshold=">1M" />
        <Rank name="Tuna Fish" image={tunaFish} threshold=">500k" />
        <Rank name="Octopus" image={octopus} threshold=">250k" />
        <Rank name="Crab" image={crab} threshold=">100k" />
        <Rank name="Shrimp" image={shrimp} threshold="<100k" />
      </div>
      <HelpUs />
      <SiteSection path="hodlRanks.contentSection" image="contentImage" />
    </Root>
  );
};

export default HODLRanks;
