import React, { useRef, useEffect, useState } from 'react';
import { useWeb3 } from 'web3';
import Modal from 'components/Common/Modal';
import { Message } from './style';
import {
  CONTRACTADDRESS,
  WBNBADDRESS,
  BUYENERGYADDRESS,
  getBuyEnergyPrices,
} from 'web3';
import { formatHODL } from 'utils';
import LoadingOverlay from 'components/Common/LoadingOverlay';

const Wallet = ({ header }) => {
  const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
  const truncateEthAddress = (address) => {
    const match = address && address.match(truncateRegex);
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
  };

  const loadingOverlay = useRef();

  const contractMap = {
    HODL: CONTRACTADDRESS,
    BNB: WBNBADDRESS,
  };

  const modal = useRef();
  const [selectedAmount, setSelectedAmount] = useState(15);
  const [selectedCurrency, setSelectedCurrency] = useState(WBNBADDRESS);
  const [editUsername, setEditUsername] = useState(false);
  const [allowance, setAllowance] = useState(0);

  const { wallet, disconnect, activate, connected } = useWeb3();
  const [newUsername, setNewUsername] = useState(wallet.username || '');
  const [alreadyUsed, setAlreadyUsed] = useState('');
  const [notEnoughFunds, setNotEnoughFunds] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [usdPrice, setUSDPrice] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [userBalance, setUserBalance] = useState(0);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const closeMenu = () => {
    try {
      document.getElementById('menuCheckbox').checked = false;
      document.body.classList.remove('noscroll');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (header && params.get('p2e-usermenu') !== null) modal.current.show();
  }, []);

  const saveNewUsername = async () => {
    if (newUsername !== wallet.username) {
      loadingOverlay.current.show('Updating username');
      setProcessing(true);
      fetch('/api/p2e/username', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          newUsername: JSON.stringify({
            wallet: wallet.account,
            username: newUsername,
          }),
        }),
        credentials: 'include',
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            setAlreadyUsed(newUsername);
            loadingOverlay.current.cancel();
            setProcessing(false);
          } else {
            loadingOverlay.current.update('Get wallet data');
            activate().then(async () => {
              loadingOverlay.current.update('Username updated', 2500);
              document
                .getElementById('p2eEnergyBalance')
                .classList.remove('highlight');
              document.getElementById('p2eUsername').classList.add('highlight');
              setProcessing(false);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          loadingOverlay.current.cancel();
          setProcessing(false);
        });
    }
    setEditUsername(false);
  };

  const buyEnergy = async () => {
    loadingOverlay.current.show('Your purchase is being processed');
    setProcessing(true);
    wallet
      .buyEnergy(selectedAmount, selectedCurrency)
      .then(() => {
        loadingOverlay.current.update('Get wallet data');
        activate().then(async () => {
          loadingOverlay.current.update('Purchase complete', 2500);
          document.getElementById('p2eUsername').classList.remove('highlight');
          document
            .getElementById('p2eEnergyBalance')
            .classList.add('highlight');
          setProcessing(false);
        });
      })
      .catch((error) => {
        console.log(error);
        loadingOverlay.current.cancel();
        setProcessing(false);
        if (error.message.includes('insufficient funds')) {
          setNotEnoughFunds(true);
        }
      });
  };

  const approveContract = async () => {
    loadingOverlay.current.show('Waiting for approval');
    setProcessing(true);
    wallet
      .approveContract(BUYENERGYADDRESS, selectedCurrency)
      .then(() => {
        loadingOverlay.current.update('Get wallet data');
        wallet
          .getApproval(BUYENERGYADDRESS, selectedCurrency)
          .then(async (a) => {
            setAllowance(a);
            loadingOverlay.current.update('Approval completed', 2500);
            setProcessing(false);
          })
          .catch((error) => {
            console.log(error);
            loadingOverlay.current.cancel();
            setProcessing(false);
          });
      })
      .catch((error) => {
        console.log(error);
        loadingOverlay.current.cancel();
        setProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        loadingOverlay.current.cancel();
        setProcessing(false);
      });
  };

  useEffect(() => {
    setNewUsername(wallet.username);
  }, [wallet]);

  useEffect(() => {
    if (selectedCurrency !== WBNBADDRESS)
      wallet
        .getApproval(BUYENERGYADDRESS, selectedCurrency)
        .then((a) => setAllowance(a));
  }, [selectedCurrency]);

  useEffect(() => {
    getBuyEnergyPrices(selectedCurrency, selectedAmount).then((p) => {
      setUSDPrice(p.usd);
      setTokenPrice(p.tokenAmount);
    });
    switch (selectedCurrency) {
      case WBNBADDRESS:
        setUserBalance(wallet.balanceBNB.toFixed(5));
        break;
      case CONTRACTADDRESS:
        setUserBalance(wallet.balance.toFixed(0));
        break;
      default:
        break;
    }
  }, [selectedAmount, selectedCurrency, wallet]);

  const isBrowser = () => typeof window !== 'undefined';
  const isPlayToEarn = () =>
    window.location.pathname.startsWith('/play-to-earn');

  const [pageLoad, setPageLoad] = useState(true);
  useEffect(() => {
    if (isPlayToEarn() && header && !pageLoad && connected) {
      modal.current.show();
    }
    setPageLoad(false);
  }, [connected]);

  return (
    <>
      {isBrowser() && isPlayToEarn() ? (
        <>
          <a
            className={`button blue hidable ${
              header ? '' : 'hero-cta-wrapper__hero-cta'
            }`}
            onClick={() => {
              closeMenu();
              modal.current.show();
            }}
          >
            P2E ACCOUNT
          </a>
        </>
      ) : (
        <>
          {wallet.account && (
            <a className="button disconnect blue hidable" onClick={disconnect}>
              {truncateEthAddress(wallet.account)}
            </a>
          )}
        </>
      )}

      <Modal className="scaled-06" usedRef={modal} blocked={processing}>
        <LoadingOverlay usedRef={loadingOverlay} />
        <Message>
          <h3>P2E Account</h3>
          <div className="center">
            <table>
              <tbody>
                <tr>
                  <td className="bold">Wallet:</td>
                  <td>{truncateEthAddress(wallet.account)}</td>
                </tr>

                <tr>
                  <td className="bold">Energy Balance:</td>
                  <td id="p2eEnergyBalance">
                    {Number(wallet.energy).toFixed(1)} Units
                  </td>
                </tr>
                <tr>
                  <td className="bold">Username:</td>
                  <td id="p2eUsername">
                    {editUsername ? (
                      <input
                        value={newUsername}
                        onChange={(e) => setNewUsername(e.target.value)}
                      ></input>
                    ) : (
                      `${wallet.username}`
                    )}
                  </td>
                  <td>
                    {editUsername ? (
                      <a
                        className="button button-small blue"
                        onClick={() => saveNewUsername()}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginLeft: '20px' }}
                      >
                        SAVE
                      </a>
                    ) : (
                      <a
                        className="button button-small blue"
                        onClick={() => {
                          setEditUsername(true);
                          setAlreadyUsed('');
                        }}
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginLeft: '20px' }}
                      >
                        EDIT
                      </a>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" style={{ textAlign: 'center' }}>
                    {alreadyUsed !== '' && (
                      <div className="error">{alreadyUsed} not available</div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="bold">HODL Hands:</td>
                  <td>{wallet.balanceHANDS}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <span />
          <div className="center">
            <div className="vstack">
              <div className="center">
                <strong>Buy Energy</strong>
              </div>
              <div className="center">
                <label htmlFor="token">
                  Currency Type
                  <select
                    name="token"
                    id="token"
                    onChange={(e) => {
                      setSelectedCurrency(e.target.value);
                      setNotEnoughFunds(false);
                    }}
                  >
                    <option value={WBNBADDRESS}>BNB</option>
                    <option value={CONTRACTADDRESS}>HODL</option>
                  </select>
                </label>
                <label htmlFor="amount">
                  Purchase Amount
                  <select
                    name="amount"
                    id="amount"
                    onChange={(e) => {
                      setSelectedAmount(e.target.value);
                      setNotEnoughFunds(false);
                    }}
                  >
                    <option value="15">15 Units</option>
                    <option value="40">40 Units</option>
                    <option value="100">100 Units</option>
                  </select>
                </label>
              </div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td className="bold">Price:</td>
                      <td>
                        $
                        {Number(usdPrice).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}
                      </td>
                      <td style={{ padding: '0 0.2rem' }}>|</td>
                      <td>
                        {Number(tokenPrice) > 1000 * 10 ** 3
                          ? `~` + formatHODL(tokenPrice, false, 1)
                          : Number(tokenPrice).toLocaleString('en-US')}{' '}
                        {
                          Object.entries(contractMap).find(
                            ([key, val]) => val === selectedCurrency
                          )[0]
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="bold">Balance:</td>
                      <td colSpan={3}>
                        {Number(userBalance) > 1000 * 10 ** 3
                          ? `~` + formatHODL(userBalance, false, 1)
                          : Number(userBalance).toLocaleString('en-US')}{' '}
                        {
                          Object.entries(contractMap).find(
                            ([key, val]) => val === selectedCurrency
                          )[0]
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="center">
                {Number(userBalance) < Number(tokenPrice) ? (
                  <>
                    <div className="error">
                      NEED MORE{' '}
                      {
                        Object.entries(contractMap).find(
                          ([key, val]) => val === selectedCurrency
                        )[0]
                      }
                    </div>
                  </>
                ) : (
                  <>
                    {selectedCurrency === WBNBADDRESS || allowance > 0 ? (
                      <a
                        className="button blue"
                        onClick={() => buyEnergy()}
                        target="_blank"
                        rel="noreferrer"
                      >
                        COMPLETE PURCHASE
                      </a>
                    ) : (
                      <a
                        className="button blue"
                        onClick={() =>
                          approveContract(BUYENERGYADDRESS, selectedCurrency)
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        APPROVE
                      </a>
                    )}
                  </>
                )}
              </div>
              <div style={{ marginTop: '1rem' }}>
                {notEnoughFunds && (
                  <div className="error">
                    Not enough{' '}
                    {
                      Object.entries(contractMap).find(
                        ([key, val]) => val === selectedCurrency
                      )[0]
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
          <span></span>
          <div className="center disconnect">
            <a
              className="button blue"
              href="#"
              data-modal="buyNFTs"
              onClick={() => modal.current.hide()}
              style={{ marginRight: '1rem' }}
            >
              Buy HODL Hands
            </a>
            <a
              className="button blue"
              onClick={() => {
                disconnect();
                modal.current.hide();
              }}
            >
              Disconnect
            </a>
          </div>
        </Message>
      </Modal>
    </>
  );
};

export default Wallet;
