import React from 'react';
import { Root } from './style';

import { formatNumber, useSiteData } from 'utils';
import { useWeb3 } from 'web3';

const Stats = () => {
  const { bsc } = useWeb3();
  const { index } = useSiteData().pages;

  return (
    <Root className="stats">
      <ul className="body-width">
        <li>
          <label>TOTAL REWARDS PAID</label>
          <span>{formatNumber(bsc.totalClaimedBNB)} BNB</span>
        </li>
        <li>
          <label>Reward Pool</label>
          <span>${formatNumber(bsc.bnbUsdPrice * bsc.rewardPoolBNB, 0)}</span>
        </li>
        <li>
          <label>Hodlers</label>
          <span>{formatNumber(parseInt(index.hodlers) || bsc.hodlers, 0)}</span>
        </li>
        <li>
          <label>Market Cap</label>
          <span>
            $
            {formatNumber(
              Math.round(bsc.hodlUsdPrice * (10000000000 - bsc.burnWalletHODL))
            )}
          </span>
        </li>
        <li>
          <label>Liquidity Pool</label>
          <span>${formatNumber(Math.round(bsc.liquidityPoolUSD, 0))}</span>
        </li>
        <li>
          <label>Liquidity Lock</label>
          <span>Jan 2280</span>
        </li>
      </ul>
    </Root>
  );
};

export default Stats;
