import React from 'react';
import { Root } from './style';

import { formatNumber, useSiteData } from 'utils';

import PageHeader from 'components/Common/PageHeader';
import RawHTML from 'components/Common/RawHTML';
import SiteImage from 'components/Common/SiteImage';
import InfoBox from 'components/Common/InfoBox';

// import vars from 'variables';
import { useWeb3 } from '../../web3';

const Exchanges = ({ pageContext: { exchanges } }) => {
  const { exchanges: page } = useSiteData().pages;

  const { bsc } = useWeb3();

  const types = {
    cex: page.cexTooltip,
    dex: page.dexTooltip,
  };

  const price = () => (bsc.hodlUsdPrice || 0).toString().substr(0, 9);
  const change24h = () => formatNumber(bsc.hodlUsdPrice24h || 0, 2);
  const change7d = () => formatNumber(bsc.hodlUsdPrice7d || 0, 2);

  const class24h = () =>
    (bsc.hodlUsdPrice24h || 0) < 0 ? 'negative' : 'positive';
  const class7d = () =>
    (bsc.hodlUsdPrice7d || 0) < 0 ? 'negative' : 'positive';

  return (
    <Root className="body-width">
      <PageHeader page="exchanges" />
      <ul>
        {exchanges.map((exchange) => (
          <li key={exchange.id} className="exchange">
            <div className="price">
              <SiteImage className="logo" image={exchange.logo} />
              <div className="stats">
                <div>
                  <span className="hodl">HODL</span>
                </div>
                <div className="right">
                  <span>$ {price(exchange)}</span>
                </div>
                <div>
                  <span>24H %</span>
                  <span>
                    <small className={class24h(exchange)}>
                      {change24h(exchange)}%
                    </small>
                  </span>
                </div>
                <div className="right">
                  <span>7D %</span>
                  <span>
                    <small className={class7d(exchange)}>
                      {change7d(exchange)}%
                    </small>
                  </span>
                </div>
              </div>
            </div>
            <div className="info">
              <InfoBox info={types[exchange.type]}>{exchange.type}</InfoBox>
              <h3>{exchange.name}</h3>
              <RawHTML html={exchange.information} />
              <div className="links">
                <a href={exchange.buyURL} target="_blank" rel="noreferrer">
                  Buy now
                </a>
                <a href={exchange.chartURL} target="_blank" rel="noreferrer">
                  View chart
                </a>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </Root>
  );
};

export default Exchanges;
